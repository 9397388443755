@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-6px);
    }

    50% {
        transform: translateX(6px);
    }

    75% {
        transform: translateX(-6px);
    }

    100% {
        transform: translateX(0);
    }
}

@define-mixin setAnimationAnchor $xAxis, $yAxis {
    transform-origin: $xAxis $yAxis;
}

@define-mixin animatePropertySlower $property {
    transition-property: $property;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
}

@define-mixin animatePropertyFaster $property {
    transition-property: $property;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
}

@define-mixin animateVisibilityAndSizeSlower {
    @mixin animatePropertySlower single-arg(opacity, transform);
}

@define-mixin animateVisibilityAndSizeFaster {
    @mixin animatePropertyFaster single-arg(opacity, transform);
}

@define-mixin visibleAndNormalSize {
    opacity: 1;
    transform: none;
}

@define-mixin hiddenAndSmallSize {
    opacity: 0;
    transform: scale(0.9);
}

@define-mixin popupAppearanceAnimation {
    &:global(.appear),
    &:global(.enter) {
        @mixin hiddenAndSmallSize;
    }

    &:global(.appear-active),
    &:global(.enter-active) {
        @mixin visibleAndNormalSize;
        @mixin animateVisibilityAndSizeFaster;
    }

    &:global(.exit) {
        @mixin visibleAndNormalSize;
    }

    &:global(.exit-active) {
        @mixin hiddenAndSmallSize;
        @mixin animateVisibilityAndSizeSlower;
    }
}

@define-mixin slideAnimationBase $initialTransform, $targetTransform {
    &:global(.appear),
    &:global(.enter) {
        transform: $initialTransform;
    }

    &:global(.appear-active),
    &:global(.enter-active) {
        @mixin animatePropertySlower transform;
        transform: $targetTransform;
    }

    &:global(.exit) {
        transform: $targetTransform;
    }

    &:global(.exit-active) {
        @mixin absoluteParentSize;
        @mixin animatePropertySlower transform;
        transform: $initialTransform;
    }

    &:global(.exit-done) {
        transform: $initialTransform;
    }
}

@define-mixin fadeAnimation {
    &:global(.enter) {
        opacity: 0;
    }

    &:global(.enter-active) {
        @mixin animatePropertySlower opacity;
        opacity: 1;
    }

    &:global(.exit) {
        opacity: 1;
    }

    &:global(.exit-active) {
        @mixin animatePropertySlower opacity;
        opacity: 0;
    }
}

@define-mixin fadeAnimationWithAbsolute {
    @mixin fadeAnimation;

    &:global(.enter-active) {
        @mixin absoluteParentSize;
    }

    &:global(.exit-active) {
        @mixin absoluteParentSize;
    }
}

@define-mixin horizontalSlideAnimation $from: 100%, $to: 0% {
    $func: translateX;
    $initialTransform: $func($from);
    $targetTransform: $func($to);

    @mixin slideAnimationBase $initialTransform, $targetTransform;
}

@define-mixin verticalSlideAnimation $from: 100%, $to: 0% {
    $func: translateY;
    $initialTransform: $func($from);
    $targetTransform: $func($to);

    @mixin slideAnimationBase $initialTransform, $targetTransform;
}

@define-mixin horizontalSlideAnimationWithVisibility $from {
    @mixin horizontalSlideAnimation $from;
    visibility: hidden;

    &:global(.enter-active),
    &:global(.enter-done),
    &:global(.exit-active) {
        visibility: visible;
    }
}

@define-mixin buttonScaleAnimationOnChild $elementSelector, $growSize: 1.1, $shrinkSize: 0.9 {
    &:hover {
        & $elementSelector {
            transform: scale($growSize);
        }
    }

    &:active {
        & $elementSelector {
            transform: scale($shrinkSize);
        }
    }

    & $elementSelector {
        @mixin buttonTransitionBehavior transform;
    }
}

@define-mixin buttonTransitionBehavior $property {
    transition-property: $property;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}

@define-mixin topHideAnimation {
    @mixin animatePropertySlower top;
}

@define-mixin collapseAnimation $property: height {
    transition-property: $property;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
}

@define-mixin imageHoverAnimation $property: opacity {
    transition-property: $property;
    transition-duration: 200ms;
    transition-timing-function: ease;
}

@define-mixin svgIconFillAnimation {
    & svg {
        transition-property: fill;
        transition-duration: 300ms;
        transition-timing-function: ease-out;
    }
}

@define-mixin fadeInAnimation $duration: 800ms, $timingFunction: ease-in-out {
    &:global {
        animation: FadeIn $duration $timingFunction;
    }
}

@define-mixin shakeAnimation $duration: 300ms, $timingFunction: ease-in-out {
    &:global {
        animation: Shake $duration $timingFunction;
    }
}
